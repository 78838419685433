export function CalculatePercentage(correctAnswers: string[], incorrectAnswers: string[], skippedQuestions: string[]): number {
    const nonEmptyCorrectAnswers = correctAnswers.filter(item => item !== '').length;
    const nonEmptyIncorrectAnswers = incorrectAnswers.filter(item => item !== '').length;
    const nonEmptySkippedQuestions = skippedQuestions.filter(item => item !== '').length;
  
    if (nonEmptyCorrectAnswers + nonEmptyIncorrectAnswers + nonEmptySkippedQuestions === 0) {
      return 0; // Avoid division by zero if there are no answers
    }
  
    const score = Math.round((nonEmptyCorrectAnswers / (nonEmptyCorrectAnswers + nonEmptyIncorrectAnswers + nonEmptySkippedQuestions)) * 100);
    return score;
  }