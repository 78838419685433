import React from "react";
import { Link } from "react-router-dom";
import "../../App.css";
import "./legalcheckbox.css";


export const LegalCheckbox = ({containerId, textColor="white-text"}) => {
    return (
        <div className="flex-row legalCheckbox-container">
            <input type="checkbox" className='legalCheckbox-checkbox' onChange={() => {
            // This can be overriden via inspect html
            const buttonContainer = document.getElementById(containerId);
                buttonContainer.classList.toggle("disabled");
            }}/>
            <p className={textColor} style={{marginRight: "5px"}}>I agree to the <Link className='orange-text' style={{marginRight: "5px"}} to="/tandc"> terms and conditions</Link> and the<Link className='orange-text' to="/privacy"> privacy policy</Link></p>
        </div>
    )
}