import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { IsLoggedIn } from "../../IsLoggedIn";
import { ReadCatalog } from "../../db/Read/ReadCatalog";
import { Error } from "../Error/Error";
import { Loading } from "../Loading/loading";
import { SelectionPage } from "./SelectionPage/SelectionPage";
import { QuestionPage } from "../PracticePage/QuestionPage/QuestionPage";
import { ReviewPage } from "../PracticePage/ReviewPage/ReviewPage";
import { ResultPage } from "../PracticePage/ResultPage/ResultPage";
import { ReadAllCatalogQuestionsParameter } from "../../db/Read/ReadAllCatalogQuestionsParameter.ts";
import { LoadingOverlay } from "../../components/LoadingOverlay/LoadingOverlay.jsx";
import { ReadExamAttemptsByExam } from "../../db/Read/ReadExamAttemptsByExam.ts";
import { AggregateIncorrectQuestions } from "./AggregateIncorrectQuestions.ts";
import { AggregateSkippedQuestions } from './AggregateSkippedQuestions.ts';

import Cookies from "js-cookie";

import "./TrainingPage.css";
import "../../App.css";
import { Notification } from './../../Notification';
import { CreateExamAttempt } from "../../db/Create/CreateExamAttempt.ts";

export const TrainingPage = () => {
    //Page states
    const [started, setStarted] = useState(false);
    const [finished, setFinished] = useState(false);

    //ReadAllCatalogQuestions
    const [readAllCatalogQuestions, setReadAllCatalogsQuestions] = useState(null);
    const [readAllCatalogQuestionsFetching, setReadAllCatalogQuestionsFetching] = useState(false);
    const [readAllCatalogQuestionsError, setReadAllCatalogQuestionsError] = useState(null);

    //ReadCatalog
    const [readCatalog, setReadCatalog] = useState(false);
    const [readCatalogFetching, setReadCatalogFetching] = useState(true); // We want to get it straight away
    const [readCatalogError, setReadCatalogError] = useState(null);

    //ReadUserExamAttempts
    const [readExamAttemptsByExamFetching, setReadExamAttemptsByExamFetching] = useState(true);
    const [readExamAttemptsByExamError, setReadExamAttemptsByExamError] = useState(null);
    const [readExamAttemptsByExam, setReadExamAttemptsByExam] = useState(null);

    //If questions are ready for training
    // const [questionsReady, setQuestionsReady] = useState(false);

    const [selectedQuestionOption, setSelectedQuestionOption] = useState(null);
    const [selectedKnowledgeAreaOption, setSelectedKnowledgeAreaOption] = useState(null);

    //Checkboxes
    const [questionOne, setQuestionOne] = useState(false);
    const [questionTwo, setQuestionTwo] = useState(false);
    const [questionThree, setQuestionThree] = useState(false);
    const [questionFour, setQuestionFour] = useState(false);

    //Time
    const [time, setTime] = useState(0);
    console.log(time); // Removes eslint error

    const [knowledgeAreas, setKnowledgeAreas] = useState(null);
    const [correct, setCorrect] = useState([]);
    const [incorrect, setIncorrect] = useState([]);
    const [skipped, setSkipped] = useState([]);
    const [selected, setSelected] = useState([]);

    const [questionNo, setQuestionNo] = useState(1);
    const [reviewQuestions, setReviewQuestions] = useState(false);

    const [selectionComplete, setSelectionComplete] = useState(false);

    //CreateExamAttempt
    const [createExamAttemptError, setCreateExamAttemptError] = useState(null);
    
    const {name} = useParams();
    
    if(readCatalogFetching){
        const tokenFromCookie = Cookies.get('jwtToken');
        if(!tokenFromCookie){
            window.location.href = "/login"
        }
        ReadCatalog(name, Cookies.get('jwtToken'))
        .then(res => {
            setReadCatalog(res.data.detail[0]);
        })
        .catch(err => {
            setReadCatalogError(err);
        })
        .finally(() => {
            setReadCatalogFetching(false);
        });  
    }

    // Save exam attempt
    useEffect(() => {
        if(finished)
        {
            //Save result to users exam attempts
            const timeTaken = (readCatalog.time * 60) - time;
            CreateExamAttempt(name, correct.map(el => el._id), incorrect.map(el => el._id), skipped.map(el => el._id), timeTaken, Cookies.get("jwtToken")).then(async (res) => {
                Notification("Success", "Exam Attempt Saved!")
            })
            .catch(err => {
                setCreateExamAttemptError(err);
            })
        }
    }, [name, finished, correct, incorrect, skipped, readCatalog, time])

    useEffect(() => {
        // If the user is done choosing their options, we can get the questions
        if(selectionComplete){
            
            setReadAllCatalogQuestionsFetching(true);
            IsLoggedIn(`training/${name}`);
            const tokenFromCookie = Cookies.get('jwtToken');
            ReadAllCatalogQuestionsParameter(tokenFromCookie, name, "Developing Azure App Service")
            .then(async (res) => {
                const questions = res.data.detail;
                if (selectedQuestionOption === "All questions"){
                    setReadAllCatalogsQuestions(questions);
                }
                else if (selectedQuestionOption === "Incorrect questions"){
                    AggregateIncorrectQuestions(readExamAttemptsByExam);
                }
                else if (selectedQuestionOption === "Skipped questions"){
                    AggregateSkippedQuestions(readExamAttemptsByExam);
                }
                else if (selectedQuestionOption === "Unanswered"){
                    // TODO we need to removed all questions which are seen
                    setReadAllCatalogsQuestions(questions);
                }
                else {
                    setReadAllCatalogQuestionsError("Unknown question selection, please refresh and try again");
                }
            })
            .catch(err => {
                setReadAllCatalogQuestionsError(err)
            })
            .finally(() => {
                setReadAllCatalogQuestionsFetching(false);
                setStarted(true);
            })
        }
    }, [selectionComplete, name, readExamAttemptsByExam, selectedQuestionOption])

    useEffect(() => {
        if (readExamAttemptsByExamFetching) {
            ReadExamAttemptsByExam(Cookies.get("jwtToken"), name)
            .then(el => {
                console.log("Reached ReadExamAttemptsByExam");
                setReadExamAttemptsByExam(el.data.detail);
            })
            .catch(err => {
                setReadExamAttemptsByExamError(err);
            })
            .finally(() => {
                setReadExamAttemptsByExamFetching(false);
            })
        }
    }, [readExamAttemptsByExamFetching, name]);

    useEffect(() => {
        if (readAllCatalogQuestionsFetching && readExamAttemptsByExamError) {
            // AggregateExamResults()
        }

    }, [readAllCatalogQuestionsFetching, readExamAttemptsByExamError])

    useEffect(() => {
        IsLoggedIn("training");
    }, [])

    if (readCatalogFetching) {
        return (
            <Loading/>
        )
    }
    else if (readCatalogError || readAllCatalogQuestionsError || createExamAttemptError) {
        return (
            <Error title="Error" message="Oops, there has been a problem. please refresh the page"/>
        )
    }
    // Display page components
    else {
        if (!started) {
            return (
                <>
                    {
                        readAllCatalogQuestionsFetching ? (
                            <LoadingOverlay content={"Loading your questions..."}/>
                        ) : (
                            <></>
                        )
                    }
                    <SelectionPage name={name} setSelectionComplete={setSelectionComplete} selectedQuestionOption={selectedQuestionOption} setSelectedQuestionOption={setSelectedQuestionOption} selectedKnowledgeAreaOption={selectedKnowledgeAreaOption} setSelectedKnowledgeAreaOption={setSelectedKnowledgeAreaOption}/>    
                </>
            )
        }
        else {
            // Training has started but not finished yet
            if (!finished) {
                if (readAllCatalogQuestions) {
                    return (
                        <QuestionPage readAllCatalogQuestions={readAllCatalogQuestions} setQuestionNo={setQuestionNo} questionNo={questionNo} setQuestionOne={setQuestionOne} 
                                questionOne={questionOne} setQuestionTwo={setQuestionTwo} questionTwo={questionTwo} setQuestionThree={setQuestionThree} questionThree={questionThree} 
                                setQuestionFour={setQuestionFour} questionFour={questionFour} readCatalog={readCatalog} setFinished={setFinished}
                                setSkipped={setSkipped} setCorrect={setCorrect} setIncorrect={setIncorrect} setTime={setTime} setSelected={setSelected} setKnowledgeAreas={setKnowledgeAreas}/>         
                    )
                } 
                else {
                    // Selected option has no questions, ask the user for different options
                    Notification("warning", "No questions available", "Sorry, there are no questions available for those options.")
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000)
                }

            }
            // Training has finished
            else{
                // If we're on the review page (list of marked answers)
                if(reviewQuestions)
                {
                    return (
                        <ReviewPage name={name} setReviewQuestions={setReviewQuestions} readAllCatalogQuestions={readAllCatalogQuestions} correct={correct} 
                            incorrect={incorrect} skipped={skipped} selected={selected}/>
                    )
                }
                // If we're on the result page (graphs and navigation buttons)
                else{
                    return (
                        <ResultPage name={name} correct={correct} incorrect={incorrect} skipped={skipped} readCatalog={readCatalog} setReviewQuestions={setReviewQuestions} knowledgeAreas={knowledgeAreas}/>
                    )
                }
            }

        }

    }

}