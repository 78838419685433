export function GoogleAuthRequest(): void {
    // Google's OAuth 2.0 endpoint for requesting an access token
    const oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';

    // Create <form> element to submit parameters to OAuth 2.0 endpoint.
    const form = document.createElement('form');
    form.setAttribute('method', 'GET'); // Send as a GET request.
    form.setAttribute('action', oauth2Endpoint);

    // Generate a unique nonce value
    const nonce = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

    // Parameters to pass to OAuth 2.0 endpoint.
    const params = {
        client_id: '952247400628-46mf4tp6dm5ljgcv2nldjb0nqq13gt0b.apps.googleusercontent.com',
        redirect_uri: 'http://localhost:3000/authcallback',
        response_type: 'token id_token',
        scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
        include_granted_scopes: 'true',
        state: 'pass-through value',
        nonce: nonce
    };

    // Add form parameters as hidden input values.
    for (const p in params) {
        const input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', p);
        input.setAttribute('value', params[p]);
        form.appendChild(input);
    }

    // Add form to page and submit it to open the OAuth 2.0 endpoint.

    document.body.appendChild(form);
    form.submit();
}

