import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import { HelmetProvider } from 'react-helmet-async';
import { MantineProvider } from '@mantine/core';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <MantineProvider theme={{ colorScheme: 'light' }}>
        <App />
      </MantineProvider>
    </HelmetProvider>
  </React.StrictMode>
);
