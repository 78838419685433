import React from "react";

function StarHalf() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      fill="#FFD700"
      style={{margin: "2px", transform: "rotateY(180deg)"}}
      version="1.1"
      viewBox="0 0 490.655 490.655"
      xmlSpace="preserve"
    >
      <path d="M490.102 188.327c-1.323-3.84-4.693-6.613-8.704-7.147L326.902 160.7 254.88 16.317c-3.584-7.232-15.509-7.232-19.093 0l-72.021 144.384L9.27 181.181a10.69 10.69 0 00-8.704 7.147 10.656 10.656 0 002.56 10.965l113.301 113.301-30.869 154.389a10.705 10.705 0 003.989 10.581c3.243 2.475 7.637 2.88 11.243 1.067l144.555-72.277L389.9 478.631a10.748 10.748 0 004.757 1.131 10.72 10.72 0 006.485-2.197 10.73 10.73 0 003.989-10.581l-30.869-154.389 113.301-113.301c2.837-2.86 3.819-7.105 2.539-10.967zm-234.112 158.4c0 4.032-2.283 7.744-5.888 9.536l-90.389 45.184a10.77 10.77 0 01-4.779 1.131 10.72 10.72 0 01-6.485-2.197 10.73 10.73 0 01-3.989-10.581l18.24-91.243-68.31-68.331a10.656 10.656 0 01-2.56-10.965c1.323-3.84 4.693-6.613 8.704-7.147l91.264-12.096 43.968-88.107c2.197-4.437 7.168-6.741 11.989-5.632a10.656 10.656 0 018.235 10.389v230.059z"></path>
    </svg>
  );
}

export default StarHalf;
