import React, {useState, useEffect} from "react";
import { ReadAllCatalogs } from "../../db/Read/ReadAllCatalogs";
import { Error } from "../Error/Error";
import { Loading } from "../Loading/loading";
import { CourseList } from "../../components/CourseList/CourseList";
import { ReadUserCatalog } from './../../db/Read/ReadUserCatalog';
import { IsLoggedIn } from "../../IsLoggedIn";

import Cookies from "js-cookie";

import "../../App.css";
import "./CatalogPage.css";

export const CatalogPage = () => {
    const [isFetching, setIsFetching] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    // const [filter, setFilter] = useState("Java");
    // const [filteredArray, setFilteredArray] = useState([]);

    const [isFetchingUser, setIsFetchingUser] = useState(true);
    const [dataUser, setDataUser] = useState(null);

    useEffect(() => {
        if (isFetching) {
          ReadAllCatalogs()
            .then((res) => {
              setData(res.data.detail);
            })
            .catch((err) => {
              setError(err);
            })
            .finally(() => {
              setIsFetching(false);
            });
        }
    }, [isFetching]);

    useEffect(() => {
        const tokenFromCookie = Cookies.get('jwtToken');
        if(IsLoggedIn(`catalog`)){
            ReadUserCatalog(tokenFromCookie)
            .then(res => {
                setDataUser(res.data.detail);
            })
            .finally(() => {
                setIsFetchingUser(false)
            });  
        }
    }, [data])


    const getPurchasedList = () => {
        const temp = [];
        data?.forEach(el => {
            if(dataUser.includes(el.name))
            {
                temp.push(true);
            }
            else{
                temp.push(false);
            }
        })

        return temp;
    }
    

    if(error){
        return <Error title="Internal Server Error" message={error.message}/>
    }
    else if(isFetching || isFetchingUser){
        return (
            <Loading/>
        )
    }else{
        return (
            <div class="min-height-inherit primary-background padding-top-100">
                <div className="catalogPage-section1 horizontal-align flex-column">
                    <h1 class="color-white font-size-4rem letter-spacing-2px margin-bottom-20px">
                        Catalog
                    </h1>
                    <h2 class="font-size-21 white-text text-align-center margin-bottom-5px">
                        All the practice questions you need to ace your exam.
                    </h2>
                    <h2 class="font-size-21 white-text text-align-center margin-bottom-30px">
                        All here in one place.
                    </h2>
                    <input placeholder="Find a product" className="catalogPage-input" type="text" onChange={event => {}}/>
                </div>

                <div className="catalogPage-section2 greyBackground-image">
                    <CourseList data={data} error={error} isFetching={isFetching} purchasedList={getPurchasedList()}/>
                </div>
            </div>
        )
    }
}