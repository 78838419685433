import { Post } from '../post';
import { getURL } from '../getURL';

export const ReadTryAFew = (
  catalogName: string,
): Promise<any> => {
  return Post(getURL() + "tryAFew", 
    {
        catalogName: catalogName
    });
};
