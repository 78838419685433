import React from "react";
import { Button } from "../../../components/Button/Button";

export const SelectionPage = ({name, setSelectionComplete, selectedQuestionOption, setSelectedQuestionOption, selectedKnowledgeAreaOption, setSelectedKnowledgeAreaOption}) => {
    const handleQuestionSelectionChange = (event) => {
        setSelectedQuestionOption(event.target.value);
    }

    const handleKnowledgeAreaSelectionChange = (event) => {
        setSelectedKnowledgeAreaOption(event.target.value);
    }

    return (
        <div class="full-height min-height-100vh flex-column horizontal-align primary-background padding-top-100">
            <div className="flex-row">
                <h1 class="color-white font-size-4rem letter-spacing-2px">
                    {name}
                </h1>
                <div class="everything-margin background-orange padding-10-15px border-radius-20 trainingPageTrainingText-container">
                    <h2 class="white-text font-size-3rem">
                        Training
                    </h2>
                </div>
            </div>

            <div class="margin-top-10px">
                <img class="width-30vw" src={require(`../../../imgs/${name}.png`)} alt={name} />
            </div>
            <div className="center-text flex-column">
                <div className="trainingTest-container">
                    <h1 className="trainingTest-title font-size-3rem">Exam Configuration</h1>
                    <table>
                        <tr className="trainingTest-row">
                            <td className="trainingTest-cell">Questions</td>
                            <td>
                                <select value={selectedQuestionOption} onChange={handleQuestionSelectionChange} className="trainingTest-select">
                                    <option>All questions</option>
                                    <option>Incorrect questions</option>
                                    <option>Skipped questions</option>
                                    <option>Unanswered questions</option>
                                </select>
                            </td>
                        </tr>
                        <tr className="trainingTest-row">
                            <td className="trainingTest-cell">Knowledge Area</td>
                            <td>
                                <select value={selectedKnowledgeAreaOption} onChange={handleKnowledgeAreaSelectionChange} className="trainingTest-select">
                                    <option>All knowledge areas</option>
                                    <option>Pointers</option>
                                    <option>Standard Library</option>
                                </select>
                            </td>
                        </tr>
                        <tr className="trainingTest-row">
                            <td className="trainingTest-cell">Number of Questions</td>
                            <td>
                                <input className="trainingTest-select questionInput" placeholder="All"/>
                            </td>
                        </tr>
                        <tr className="trainingTest-row">
                            <td className="trainingTest-cell">Time</td>
                            <td>
                                <input className="trainingTest-select trainingPageTime-input" type="time" />
                            </td>
                        </tr>
                    </table>
                    <Button text="Start Training Exam" func={() => {
                        setSelectionComplete(true);
                    }}/>
                </div>
            </div>
        </div>
    )
}