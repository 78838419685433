import React, { useState } from "react";

import { QuestionNavigator } from "../QuestionNavigator/QuestionNavigator.jsx";
import { PracticePageQuestion } from "../PracticePageQuestion.jsx";
import { PracticeInput } from "../PracticeInput.jsx";
import { Button } from "../../../components/Button/Button";
import { QuestionImage } from "../QuestionImage/QuestionImage";
import { CountdownTimer } from "../../../components/CountdownTimer/CountdownTimer.jsx";

import "./QuestionPage.css";


export const QuestionPage = ({time, readAllCatalogQuestions, setQuestionNo, questionNo, setQuestionOne, questionOne, setQuestionTwo, questionTwo, setQuestionThree, questionThree, 
    setQuestionFour, questionFour, readCatalog, setFinished, setSkipped, setCorrect, setIncorrect, setTime, setSelected, setKnowledgeAreas}) => {

    const [imageVisibility, setImageVisibility] = useState(true);

    const CalculateResult = () => {
        //Creating a set of all the knowledge areas
        const knowledgeAreas = new Set([]);
        const answers = JSON.parse(localStorage.getItem(readCatalog.name));
        readAllCatalogQuestions?.map((el, index) => {
            knowledgeAreas.add(el.knowledgeArea);

            //If we can't find a question number saved in the array (skipped)
            const foundElement = answers?.find((item) => item.questionNo === index + 1);

            if(!foundElement)
            {
                setSkipped(skipped => [...skipped, el]);
            }
            //Selected answer is equal to the correct answer
            else if(foundElement.answerX === el.correctAnswer)
            {
                setCorrect(correct => [...correct, el]);
            }
            else
            {
                setIncorrect(incorrect => [...incorrect, el]);
            }

            setSelected(answers);
            //Clear local storage so the answers no longer get stored.
            localStorage.clear();
            return el.answer
        })
        setKnowledgeAreas(knowledgeAreas);
    }

    const resetCheckboxes = () => {
        setQuestionOne(false);
        setQuestionTwo(false);
        setQuestionThree(false);
        setQuestionFour(false);
    }

    const VisibilityButton = ({text}) => {
        return (
            <button className={`questionImage-button ${!readAllCatalogQuestions[questionNo - 1]?.image && "display-none"}`} onClick={() => {
                setImageVisibility((prevValue) => !prevValue);
            }}>{text}</button>
        )
    }

    if (time <= 0){
        CalculateResult();
        setFinished(true);
    }

    return (
        <div id="catalogpage-entry" className="flex-column page-margin-top">
            <div className="flex-column flex-complete-center">
                <div className="timer-container">
                    <CountdownTimer time={time} catalogName={readCatalog.name} setTime={setTime}/>
                </div>
                <div className="flex-row min-width-100">
                    <QuestionNavigator noOfQuestions={readAllCatalogQuestions?.length} setQuestionNo={setQuestionNo} questionNo={questionNo}/>
                    <div className="question-container">
                        <PracticePageQuestion question={readAllCatalogQuestions[questionNo - 1]?.question} language={readAllCatalogQuestions?.catagory}/>
                        { 
                            readAllCatalogQuestions[questionNo - 1]?.image && imageVisibility ? (
                                <>
                                    <QuestionImage imageUrl={readAllCatalogQuestions[questionNo - 1]?.image}/>
                                    <VisibilityButton text={"Hide Image"}/>
                                </>
                            ) : ( 
                                <>
                                    <VisibilityButton text={"Show Image"}/>
                                </>
                            )
                        }
                        <PracticeInput examName={readCatalog.name} reset={resetCheckboxes} questionHook={questionOne} setQuestionHook={setQuestionOne} questionNo={questionNo} answerX={readAllCatalogQuestions[questionNo - 1].answerOne} language={readAllCatalogQuestions.language}/>
                        <PracticeInput examName={readCatalog.name} reset={resetCheckboxes} questionHook={questionTwo} setQuestionHook={setQuestionTwo} questionNo={questionNo} answerX={readAllCatalogQuestions[questionNo - 1].answerTwo} language={readAllCatalogQuestions.language}/>
                        {
                            readAllCatalogQuestions[questionNo - 1].answerThree ? (
                                <PracticeInput examName={readCatalog.name} reset={resetCheckboxes} questionHook={questionThree} setQuestionHook={setQuestionThree} questionNo={questionNo} answerX={readAllCatalogQuestions[questionNo - 1].answerThree} language={readAllCatalogQuestions.language}/>
                            ) : (
                                <></>
                            )
                        }
                        {
                            readAllCatalogQuestions[questionNo - 1].answerFour ? (
                                <PracticeInput examName={readCatalog.name} reset={resetCheckboxes} questionHook={questionFour} setQuestionHook={setQuestionFour} questionNo={questionNo} answerX={readAllCatalogQuestions[questionNo - 1].answerFour} language={readAllCatalogQuestions.language}/>
                            ) : (
                                <></>
                            )
                        }

                        <div className="flex-row" style={{marginTop: "10px"}}>
                            {
                                questionNo !== 1 ? (
                                    <div className="practiceButton-container">
                                        <Button text={"Back"} func={() => {   
                                            setQuestionNo(questionNo-1);
                                            resetCheckboxes();
                                        }}
                                        />
                                    </div>

                                ) : (
                                    <></>
                                )
                            }
                            {
                                questionNo !== readAllCatalogQuestions?.length ? (
                                    <div className="practiceButton-container">
                                        <Button text={"Next"} func={() => {   
                                            setQuestionNo(questionNo+1);
                                            resetCheckboxes();
                                        }}
                                        />
                                    </div>
                                ) : (
                                    <div className="practiceButton-container">
                                        <Button text={"Finish"} func={() => {   
                                            setFinished(true);
                                            CalculateResult();
                                        }}
                                        />
                                    </div>
                                )
                            }
                        </div>                                       
                    </div>
                </div>
            </div>
                
        </div>
    )
}