import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Error } from "../Error/Error";
import { Loading } from "../Loading/loading";
import { IsLoggedIn } from "../../IsLoggedIn";
import { ReadCatalog } from "../../db/Read/ReadCatalog";
import { QuestionPage } from "../PracticePage/QuestionPage/QuestionPage";
import { ReviewPage } from "../PracticePage/ReviewPage/ReviewPage";
import { ResultPage } from "../PracticePage/ResultPage/ResultPage";
import { LandingPage } from "../PracticePage/LandingPage/LandingPage";
import { ReadTryAFew } from "../../db/Read/ReadTryAFew.ts";
import { CreateExamAttempt } from "../../db/Create/CreateExamAttempt.ts";
import { Notification } from "../../Notification.js";

import Cookies from "js-cookie";

export const TryAFew = () => {
    const {exam} = useParams();

    const [readAllCatalogQuestions, setReadAllCatalogQuestions] = useState(null);
    const [readAllCatalogQuestionsFetching, setReadAllCatalogQuestionsFetching] = useState(true);
    const [readAllCatalogQuestionsError, setReadAllCatalogQuestionsError] = useState(null);

    //Page states
    const [started, setStarted] = useState(false);
    const [finished, setFinished] = useState(false);
    const [reviewQuestions, setReviewQuestions] = useState(false);

    //ReadCatalog
    const [readCatalogFetching, setReadCatalogFetching] = useState(true);
    const [readCatalogError, setReadCatalogError] = useState(false);
    const [readCatalog, setReadCatalog] = useState(false);

    //CreateExamAttempt
    const [createExamAttemptError, setCreateExamAttemptError] = useState(null);

    //Checkboxes
    const [questionOne, setQuestionOne] = useState(false);
    const [questionTwo, setQuestionTwo] = useState(false);
    const [questionThree, setQuestionThree] = useState(false);
    const [questionFour, setQuestionFour] = useState(false);

    //Time
    const [time, setTime] = useState(0);

    const [knowledgeAreas, setKnowledgeAreas] = useState(null);
    const [correct, setCorrect] = useState([]);
    const [incorrect, setIncorrect] = useState([]);
    const [skipped, setSkipped] = useState([]);
    const [selected, setSelected] = useState([]);

    const [questionNo, setQuestionNo] = useState(1);

    // Save exam attempt
    useEffect(() => {
        if(finished)
        {
            //Save result to users exam attempts
            const timeTaken = (readCatalog.time * 60) - time;
            CreateExamAttempt(exam, correct.map(el => el._id), incorrect.map(el => el._id), skipped.map(el => el._id), timeTaken, Cookies.get("jwtToken")).then(async (res) => {
                Notification("Success", "Exam Attempt Saved!")
            })
            .catch(err => {
                setCreateExamAttemptError(err);
            })
        }
    }, [exam, finished, correct, incorrect, skipped, readCatalog, time])
    
    useEffect(() => {
        if(readAllCatalogQuestionsFetching){
            IsLoggedIn(`tryafew/${exam}`);
            ReadTryAFew(exam)
            .then(async (res) => {
                setReadAllCatalogQuestions(res.data.detail);
            })
            .catch(err => {
                setReadAllCatalogQuestionsError(err)
            })
            .finally(() => {
                setReadAllCatalogQuestionsFetching(false);
            })
        }
    }, [readAllCatalogQuestionsFetching, exam])

    useEffect(() => {
        if(readCatalogFetching){
            ReadCatalog(exam)
            .then(res => {
                setReadCatalog(res.data.detail[0]);
            })
            .catch(err => {
                setReadCatalogError(err)
            })
            .finally(() => {
                setReadCatalogFetching(false)
            }); 
        }
    }, [readCatalogFetching, exam])


    if (readAllCatalogQuestionsFetching || readCatalogFetching) {
        return (
            <Loading/>
        )
    }
    else if (readAllCatalogQuestionsError || readCatalogError || createExamAttemptError) {
        return (
            <Error title={"Error"} message={"Please refresh the page and try again"}/>
        )
    }
    else {
        if(started){
            // Page with the questions to answer
            if(!finished){
                return(
                    <QuestionPage readAllCatalogQuestions={readAllCatalogQuestions} setQuestionNo={setQuestionNo} questionNo={questionNo} setQuestionOne={setQuestionOne} 
                        questionOne={questionOne} setQuestionTwo={setQuestionTwo} questionTwo={questionTwo} setQuestionThree={setQuestionThree} questionThree={questionThree} 
                        setQuestionFour={setQuestionFour} questionFour={questionFour} readCatalog={readCatalog} setFinished={setFinished}
                        setSkipped={setSkipped} setCorrect={setCorrect} setIncorrect={setIncorrect} setTime={setTime} setSelected={setSelected} setKnowledgeAreas={setKnowledgeAreas}/>
                )
            }
            else{
                // If we're on the review page (list of marked answers)
                if(reviewQuestions)
                {
                    return (
                        <ReviewPage name={exam} setReviewQuestions={setReviewQuestions} readAllCatalogQuestions={readAllCatalogQuestions} correct={correct} 
                            incorrect={incorrect} skipped={skipped} selected={selected}/>
                    )
                }
                // If we're on the result page (graphs and navigation buttons)
                else{
                    return (
                        <ResultPage name={exam} correct={correct} incorrect={incorrect} skipped={skipped} readCatalog={readCatalog} setReviewQuestions={setReviewQuestions} knowledgeAreas={knowledgeAreas}/>
                    )
                }
            }

        }
        // Landing page of the exam
        else{
            return(
                <LandingPage name={exam} readAllCatalogQuestions={readAllCatalogQuestions} readCatalog={readCatalog} setStarted={setStarted}/>
            )
        }
    }
}