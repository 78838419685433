import React from "react";

function StarFull() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      style={{margin: "2px"}}
      fill="#FFD700"
      version="1.1"
      viewBox="0 0 511.987 511.987"
      xmlSpace="preserve"
    >
      <path d="M510.991 185.097a21.27 21.27 0 00-17.515-14.72l-148.843-19.84-69.546-139.221c-7.211-14.464-30.976-14.464-38.187 0l-69.547 139.221-148.843 19.84a21.271 21.271 0 00-17.515 14.72c-2.496 7.872-.213 16.512 5.867 22.101l107.392 98.923-28.65 180.736c-1.365 8.469 2.517 16.96 9.835 21.483 7.339 4.501 16.661 4.203 23.616-.811l136.939-97.792 136.939 97.792a21.128 21.128 0 0012.395 3.989c3.883 0 7.765-1.067 11.221-3.179 7.317-4.523 11.2-13.013 9.835-21.483L397.733 306.12l107.392-98.923c6.079-5.588 8.362-14.228 5.866-22.1z"></path>
    </svg>
  );
}

export default StarFull;
