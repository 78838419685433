import React from "react";
import { PercentageBar } from "../PercentageBar/PercentageBar";
import { Button } from "../../../components/Button/Button";
import { ResultChart } from '../ResultChart/ResultChart';

export const ResultPage = ({name, correct, incorrect, skipped, readCatalog, setReviewQuestions, knowledgeAreas}) => {
    return(
        <div id="catalogpage-entry" className="flex-column center-content page-margin center-content">
            <h1 className="practicePage-title">{name} Results</h1>
            <ResultChart correct={correct?.length} incorrect={incorrect?.length} skipped={skipped?.length} 
                passPercentage={readCatalog.passPercentage} setReviewQuestions={setReviewQuestions}/>
            <h2 style={{margin: "20px 0 10px 0"}}>Knowledge Areas</h2>
            {Array.from(knowledgeAreas)?.map(el => (
                <div className="practicePageKnowledgeArea-title">
                    <h4>{el}</h4>
                    <PercentageBar correct={correct?.length} incorrect={incorrect?.length} skipped={skipped?.length}/>
                </div>
            ))}
                <div className="flex-row practicePageButton-container">
                    <div style={{margin: "0px 10px", minWidth: "200px"}}>
                        <Button text="Retry exam" func={() => {
                            window.location.reload();
                        }}/>
                    </div>    
                    <div style={{margin: "0px 10px", minWidth: "200px"}}>
                        <Button text="Back to dashboard" func={() => {
                            window.location.href="/dashboard";
                        }}/>
                    </div>
                    <div style={{margin: "0px 10px", minWidth: "200px"}}>
                        <Button text="See Progress" func={() => {
                            window.location.href=`/stats/${name}`;
                        }}/>
                    </div>
                </div>
        </div>
    )
}