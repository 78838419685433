import React, {useEffect} from "react";

function secondsToHms(seconds) {
    // Calculate hours, minutes, and remaining seconds
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    // Format the output as hh:mm:ss
    return (
        String(hours).padStart(2, '0') + ':' +
        String(minutes).padStart(2, '0') + ':' +
        String(remainingSeconds).padStart(2, '0')
    );
}

export const CountdownTimer = ({time, catalogName, setTime}) => {

    // Set interval to decrement time by one second every second
    useEffect(() => {
            const interval = setInterval(() => {
                setTime(prevTime => {
                    const newTime = prevTime - 1;
                    setTime(newTime);
                    localStorage.setItem(`${catalogName}-timer`, newTime);
                    return newTime;
                });
            }, 1000);
        
            // Cleanup interval on component unmount
            return () => clearInterval(interval);
    }, [catalogName, setTime]);  

    console.log(time);
    return (
        <p className="font-size-21"><b>{secondsToHms(time)}</b></p>
    )
}