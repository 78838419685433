import React from 'react';

import "./StatsPage.css";
import "../../App.css";

const formatDate = (dateString) => {
  const options = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };
  return new Date(dateString).toLocaleString('en-GB', options).replace(',', '');
};

const StatsTable = ({examAttempts, percentages, passPercentage}) => {
  // Sample data
  const data = [];
  examAttempts.forEach((el, index) => {
    const percentage = percentages[0]["data"][index];
    data.push({
      attempt: el.exam,
      answersCorrect: el.correctAnswers.filter(answer => answer !== "").length,
      percentage: percentages[0]["data"][index],
      pass: percentage >= passPercentage ? "Pass" : "Fail",
      date: el.dateCompleted
    });
  });

  // Columns configuration
  const columns = [
    { Header: 'Attempt', accessor: 'attempt' },
    { Header: 'Answers correct', accessor: 'answersCorrect' },
    { Header: 'Percentage', accessor: 'percentage' },
    { Header: 'Pass/Fail', accessor: 'pass' },
    { Header: 'Date', accessor: 'date' },
  ];

  if (percentages[0]["data"].length === 0){
    return (
      <div className="stats-table">
        <table>
          <thead>
            <tr className='statsTable-header statsTable-row'>
              {columns.map(column => (
                <th className='statsTable-head' key={column.accessor}>{column.Header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
          </tbody>
        </table>
      </div>
    )
  }
  else {
    return (
      <div className="stats-table">
        <table>
          <thead>
            <tr className='statsTable-header statsTable-row'>
              {columns.map(column => (
                <th className='statsTable-head' key={column.accessor}>{column.Header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {[...data].reverse().map(row => (
              <tr className="statsTable-row" key={row.id}>
                {columns.map(column => {
                  const value = column.accessor === 'date' ? formatDate(row[column.accessor]) : row[column.accessor];
                  return <td key={column.accessor}>{value}</td>;
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

};

export default StatsTable;