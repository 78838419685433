import React, { useEffect, useState } from 'react';
import { Loading } from './../Loading/loading';
import { Error } from './../Error/Error';
import { GoogleLogin } from '../../db/googleLogin.ts';

import Cookies from "js-cookie";

// Verify the ID token
const verifyIdToken = async (idToken) => {
  try {
    await fetch(`https://oauth2.googleapis.com/tokeninfo?id_token=${idToken}`);

    return true;
    // const userInfo = await response.json();
    // console.log('User Info:', userInfo);
    // Redirect or handle successful login here
    // For example, store user info in state/context
  } catch (error) 
  {
    return false;
  }
};

// Extract tokens from URL fragment
const getTokensFromUrl = () => {
  const params = new URLSearchParams(window.location.hash.substr(1));
  const accessToken = params.get('access_token');
  const idToken = params.get('id_token');
  return { accessToken, idToken };
};

const OAuthCallback = () => {
  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("There was a problem logging in, please try again");

  useEffect(() => {
    handleAuthCallback();
  }, []);

  // Use the ID token to get user info
  const handleAuthCallback = () => {
    const { accessToken, idToken } = getTokensFromUrl();
    
    if (idToken) {
      const result = verifyIdToken(idToken);
      if (result) {
        GoogleLogin(idToken, accessToken)
        .then(res => {
          // Handle response depending on whether an account was created or logged in
          if ("jwt" in res['data']['detail'])
          {
            const destination = localStorage.getItem("destination");
            localStorage.removeItem("destination");
            Cookies.set("jwtToken", res.data.detail.jwt, {expires: 1}); //might be wrong
            //If a destination after login has been set
            if(destination){
                window.location.href = `/${destination}`;
            }
            else{
                window.location.href = "/catalog"
            }
          }
          else {
            setError(true);
            setErrorMessage("Invalid server response, please try again");
          }
        })
        .catch(() => {
          setError(true);
          setErrorMessage("Google login error, please try again");
        }) // Send request to backend
        .finally(() => {
          setFetching(false);
        })

      } else {
        setError(true);
        setErrorMessage("Invalid Google Auth token, please try again");
      }
    } else {
      setError(true);
      setErrorMessage("Unable to find Google Auth token, please try again");
    }
  };

  if (error) {
    return (
      <Error title="Error" message={errorMessage}/>
    )
  }
  else if (fetching) {
    return (
      <Loading />
    )
  }

  

};

export default OAuthCallback;
