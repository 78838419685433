import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IsLoggedIn } from "../../IsLoggedIn";
import { ReadExamAttemptsByExam } from "../../db/Read/ReadExamAttemptsByExam.ts";
import { Loading } from "../Loading/loading";
import { Error } from "../Error/Error.jsx";
import { ReadCatalog } from "../../db/Read/ReadCatalog.jsx";
import { CalculatePercentage } from "./CalculatePercentage.ts";

import Cookies from 'js-cookie';
import Chart from "react-apexcharts";
import StatsTable from './StatsTable';

import "./StatsPage.css";
import "../../App.css";
import { Button } from "../../components/Button/Button.jsx";

export const StatsPage = () => {
    const {exam} = useParams();

    const [readCatalog, setReadCatalog] = useState(null);
    const [readCatalogError, setReadCatalogError] = useState(null);
    const [readExamFetching, setReadCatalogFetching] = useState(true);

    const [readExamAttemptsByExamFetching, setReadExamAttemptsByExamFetching] = useState(true);
    const [readExamAttemptsByExamError, setReadExamAttemptsByExamError] = useState(null);
    const [readExamAttemptsByExam, setReadExamAttemptsByExam] = useState(null);

    // If there are no stats to display, disable the page
    const [disablePage, setDisablePage] = useState(null);

    useEffect(() => {
        IsLoggedIn(`stats/${exam}`);
    }, [exam])

    //Look at users previous attempts and give them a nice little graph
    const options = {
        chart: {
            id: "basic-bar"
        },
        xaxis: {
            categories: [],
        },
        yaxis: {
            forceNiceScale: false,
            max: 100,
            labels: {
              formatter: (value) => value.toFixed(0) +'%',
            },
        }
    }

    const [series, setSeries] = useState([
        {
            name: "percentages",
            data: []
        }
    ]);

    useEffect(() => {
        if (series[0].data.length === 0){
            setDisablePage(true);
        }
        else {
            setDisablePage(false);
        }
    }, [series])

    useEffect(() => {
        if (readExamFetching && !readExamAttemptsByExamFetching) {
            ReadCatalog(exam, Cookies.get("jwtToken"))
            .then(el => {
                setReadCatalog(el.data.detail[0]);
            })
            .catch(err => {
                setReadCatalogError(err);
            })
            .finally(() => {
                setReadCatalogFetching(false);
            })
        }
    }, [readExamFetching, readExamAttemptsByExamFetching, exam])

    useEffect(() => {
        if (readExamAttemptsByExamFetching) {
            ReadExamAttemptsByExam(Cookies.get("jwtToken"), exam)
                .then(el => {
                    setReadExamAttemptsByExam(el.data.detail);
                    const percentages = [];
                    el.data?.detail?.forEach(el => {
                        console.log(el);
                        percentages.push(CalculatePercentage(el.correctAnswers, el.incorrectAnswers, el.skippedQuestions));
                    });
                    setSeries(prevSeries => [{ ...prevSeries[0], data: percentages }]);
                })
                .catch(err => {
                    setReadExamAttemptsByExamError(err);
                })
                .finally(() => {
                    setReadExamAttemptsByExamFetching(false);
                })
        }
    }, [readExamAttemptsByExamFetching, exam]);

    if(readExamAttemptsByExamFetching || readExamFetching)
    {
        return (
            <Loading />
        );
    }
    if(readExamAttemptsByExamError || readCatalogError)
    {
        return (
            <Error title="Error" message="There was an error fetching exam results, please try again"/>
        )
    }
    if (disablePage){
        return (
            <div className="flex-column min-height-inherit primary-background padding-100 center-text">
                <h1 className="statsPage-title">Welcome, your performance is displayed here</h1>
                <h1 className="statsPage-title">Do an exam to see your results!</h1>
                <Button text={"Back"} color={"highlight-background"} func={() => {
                    window.location.href = `/practice/${exam}`;
                }}/>
                <div className={`flex-column ${disablePage ? "statsPage-disabled" : ""}`}>
                    <div className="flex-row" style={{minHeight: "400px"}}>
                        <div className="flex-column statsPage-widget widget-background percent60-width">
                            <h1 className="">
                                Previous Results
                            </h1>
                            <StatsTable examAttempts={readExamAttemptsByExam} percentages={series} passPercentage={readCatalog.passPercentage}/>
                        </div>
                        <div className="statsPage-widget widget-background percent40-width">
                            <h1>
                                Areas to improve
                            </h1>
                        </div>
                    </div>
                    <div className="flex-row"  style={{minHeight: "400px"}}>
                        <div className="statsPage-widget widget-background percent40-width">
                            <h1>
                                Recommended Resources
                            </h1>
                        </div>
                        <div className="flex-column statsPage-widget widget-background percent60-width">
                            <h1>
                                Performance
                            </h1>
                            <div style={{padding: "auto"}}>
                                <>
                                    {
                                        disablePage ? (
                                            <></>
                                        ) : (
                                            <Chart
                                                options={options}
                                                series={series}
                                                type="line"
                                            />
                                        )
                                    }
                                </>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else
    {
        return (
            <div className="flex-column min-height-inherit primary-background padding-100 center-text">
                <h1 className="statsPage-title">Welcome, here is an overview of your previous attempts</h1>
                <Button text={"Back"} color={"highlight-background"} func={() => {
                    window.location.href = `/practice/${exam}`;
                }}/>
                <div className="flex-column">
                    <div className="flex-row" style={{minHeight: "400px"}}>
                        <div className="flex-column statsPage-widget widget-background percent60-width">
                            <h1 className="">
                                Previous Results
                            </h1>
                            <StatsTable examAttempts={readExamAttemptsByExam} percentages={series} passPercentage={readCatalog.passPercentage}/>
                        </div>
                        <div className="statsPage-widget widget-background percent40-width">
                            <h1>
                                Areas to improve
                            </h1>
                        </div>
                    </div>
                    <div className="flex-row"  style={{minHeight: "400px"}}>
                        <div className="statsPage-widget widget-background percent40-width">
                            <h1>
                                Recommended Resources
                            </h1>
                        </div>
                        <div className="flex-column statsPage-widget widget-background percent60-width">
                            <h1>
                                Performance
                            </h1>
                            <div style={{padding: "auto"}}>
                                <Chart
                                    options={options}
                                    series={series}
                                    type="line"
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

    
}