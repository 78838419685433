import React from "react"

import "./LoadingOverlay.css";

export const LoadingOverlay = ({content}) => {
    const loadingText = "Loading...";

    return (
        <div className="overlay-container">
            <div className="loading-wave">
                {loadingText.split("").map((char, index) => (
                    <span key={index}>{char}</span>
                ))}
            </div>
        </div>
    )
}