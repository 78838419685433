import { Post } from "../post";
import { getURL } from "../getURL";

export const ReadAllCatalogQuestionsParameter = (
  jwt: string,
  catalogName: string,
  knowledgeArea: string
): Promise<any> => {
  return Post(getURL() + "readAllCatalogQuestionsParameter", 
    {
        catalogName: catalogName,
        knowledgeArea: knowledgeArea
    }
    , jwt);
};
