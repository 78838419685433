import React from "react";
import { Button } from "../../../components/Button/Button";
import { PracticePageQuestion } from "../PracticePageQuestion";
import { DisabledInput } from "../DisabledInput/DisabledInput";

export const ReviewPage = ({name, setReviewQuestions, readAllCatalogQuestions, correct, incorrect, skipped, selected}) => {
    return(
        <div id="catalogpage-entry" className="flex-column center-content page-margin center-content">
            <div className="practicePageReviewTitle-container">
                <h1 className="practicePageReview-title">{name} Results</h1>
                <div className="practicePageReviewButton-container">
                    <Button text="Back" func={() => {
                        setReviewQuestions(false)
                    }}/>
                </div>
            </div>  
            {
                readAllCatalogQuestions?.map((el, index) => {
                   return(
                    <div className="practicePageReview-page">
                        <div className="flex-row">
                            <h3 className="practicePage-subtitle">Question {index + 1}: </h3>
                            {
                                correct.includes(el) ? (
                                    <h3 className="practicePage-subtitle practicePage-correct practicePage-userResponse">
                                        Correct
                                    </h3>
                                ) : (
                                    <></>
                                )
                            }
                            {
                                incorrect.includes(el) ? (
                                    <h3 className="practicePage-subtitle practicePage-incorrect practicePage-userResponse">
                                        Incorrect
                                    </h3>
                                ) : (
                                    <></>
                                )
                            }
                            {
                                skipped.includes(el) ? (
                                    <h3 className="practicePage-subtitle practicePage-skipped practicePage-userResponse">
                                        Skipped
                                    </h3>
                                ) : (
                                    <></>
                                )
                            }
                        </div>
                        <PracticePageQuestion question={el.question} language={readAllCatalogQuestions.catagory}/>
                        <DisabledInput answer={el.answerOne} selectedIncorrect={incorrect.includes(el)} selectedCorrect={correct.includes} selected={selected?.some(item => item.questionNo === index + 1 && item.answerX === el.answerOne)} language={readAllCatalogQuestions.language}/>
                        <DisabledInput answer={el.answerTwo} selectedIncorrect={el.answerTwo !== el.correctAnswer} selectedCorrect={el.answerTwo === el.correctAnswer} selected={selected?.some(item => item.questionNo === index + 1 && item.answerX === el.answerTwo)} language={readAllCatalogQuestions.language}/>
                        {
                            el.answerThree ? (
                                <DisabledInput answer={el.answerThree} selectedIncorrect={el.answerThree !== el.correctAnswer} selectedCorrect={el.answerThree === el.correctAnswer} selected={selected?.some(item => item.questionNo === index + 1 && item.answerX === el.answerThree)} language={readAllCatalogQuestions.language}/>
                            ) : (
                                <></>
                            )
                        }
                        {
                            el.answerFour ? (
                                <DisabledInput answer={el.answerFour} selectedIncorrect={el.answerFour !== el.correctAnswer} selectedCorrect={el.answerFour === el.correctAnswer} selected={selected?.some(item => item.questionNo === index + 1 && item.answerX === el.answerFour)} language={readAllCatalogQuestions.language}/>
                            ) : (
                                <></>
                            )
                        }
                        <h3 className="practicePageExplanation-title">
                            Explanation
                        </h3>
                        <p className="practicePageExplanation-desc">
                            {el.explanation}
                        </p>
                    </div>
                   )
                })
            }     
        </div>
    )
}