import React from "react";
import { useNavigate } from 'react-router-dom';
import { TandCSubsection } from "./PrivacySubsection";
import { Button } from "../../components/Button/Button";

import "./PrivacyPage.css";

export const PrivacyPage = () => {

    const navigate = useNavigate();

    return (
        <div className="flex-column complete-center full-height page-margin full-width">
            <h1 className="TandCPage-text">Privacy Policy</h1>
            <TandCSubsection title={"Effective Date: 19/07/2024"} paragraphs={[
                'Welcome to Practice With Byron ("we," "our," "us"). We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines the types of information we collect from users of our exam practice website (the "Site") and how we use, disclose, and protect that information.'
            ]}/>
            <TandCSubsection title={"1. Information We Collect"} paragraphs={[
                '1.1 Cookies - We use cookies to ensure the functionality of our Site. Cookies are small data files stored on your device that allow you to use elements of the website that require access without having to repeatedly login. These cookies are essential for the operation of our Site and cannot be disabled in our systems.',
                '1.2 Anonymous Tracking - We collect anonymous data about visits to certain pages on our Site. This information includes, but is not limited to, the number of visitors, the pages they visit, and the duration of their visits. This data is collected in an aggregated and anonymous manner and cannot be used to identify individual users.',
            ]}/>
            <TandCSubsection title={"2. Use of Information"} paragraphs={[
                '2.1 Cookies - The cookies we use are essential for the basic functionalities of our Site, such as maintaining user sessions and preferences. Without these cookies, the Site would not function properly.',
                '2.2 Anonymous Tracking Data - The anonymous tracking data we collect is used to analyze how our Site is used and to improve the user experience. This data helps us understand which pages are most popular, how users navigate through the Site, and identify areas for improvement.'
            ]}/>
            <TandCSubsection title={"3. Disclosure of Information"} paragraphs={[
                "3.1 Third Parties - We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our Site, conducting our business, or serving our users, so long as those parties agree to keep this information confidential. We may also release information when its release is appropriate to comply with the law, enforce our Site policies, or protect ours or others' rights, property, or safety."
            ]}/>
            <TandCSubsection title={"4. Data Security"} paragraphs={[
                "We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore, we cannot guarantee its absolute security."
            ]}/>
            <TandCSubsection title={"5. Changes to Our Privacy Policy"} paragraphs={[
                "We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy"
            ]}/>
         
            <div className="TandCPageButton-container">
                <Button text="Go back" func={() => {
                    navigate(-1)
                }}/>
            </div>

        </div>
    )
}