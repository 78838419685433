// Here is the logic which takes the questions by exam, knowledgeArea and questionType (correct, incorrect...)

// Define the data structure for ExamAttempt
interface ExamAttempt {
    exam: string;
    correctAnswers: string[];
    incorrectAnswers: string[];
    skippedQuestions: string[];
    timeCompleted: string;
    dateCompleted: string;
}

// Function to aggregate results from exam attempts to create three arrays of unique correct, incorrect and skipped question ids
export function AggregateSkippedQuestions(examAttempts: ExamAttempt[]): string[] {
    // Initialize sets for each category
    const skippedQuestionsSet: Set<string> = new Set();

    // Iterate over each exam attempt and aggregate results
    for (const attempt of examAttempts) {
        for (const question of attempt.skippedQuestions) {
            if (question) skippedQuestionsSet.add(question);
        }
    }

    // Convert sets to arrays for the final result
    return (Array.from(skippedQuestionsSet));
}
