import React from "react";
import "./Button.css"

import "../../App.css";

export const Button = ({text, func, color="primary-color-background"}) => {
    return (
        <div className={`flex-row custom-button ${color}`} onClick={() => {
            func();
        }}>
            <h4 className="customButton-text">{text}</h4>
        </div>
    )
}