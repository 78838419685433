import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "../Loading/loading";
import { ReadAllCatalogQuestions } from "../../db/Read/ReadAllCatalogQuestions";
import { ReadCatalog } from "../../db/Read/ReadCatalog";
import { IsLoggedIn } from "../../IsLoggedIn";
import { CreateExamAttempt } from "../../db/Create/CreateExamAttempt.ts";
import { Notification } from "../../Notification.js";
import { Error } from "../Error/Error.jsx";
import { QuestionPage } from "./QuestionPage/QuestionPage.jsx";
import { ResultPage } from "./ResultPage/ResultPage.jsx";
import { ReviewPage } from "./ReviewPage/ReviewPage.jsx";
import { LandingPage } from "./LandingPage/LandingPage.jsx";

import Cookies from "js-cookie";

import "../../App.css";
import "./PracticePage.css";

export const PracticePage = () => {
    //Page states
    const [started, setStarted] = useState(false);
    const [finished, setFinished] = useState(false);
    const [reviewQuestions, setReviewQuestions] = useState(false);

    //ReadAllCatalogQuestions
    const [readAllCatalogQuestionsFetching, setReadAllCatalogQuestionsFetching] = useState(true);
    const [readAllCatalogQuestionsError, setReadAllCatalogQuestionsError] = useState(null);
    const [readAllCatalogQuestions, setReadAllCatalogsQuestions] = useState(null);

    //ReadCatalog
    const [readCatalogFetching, setReadCatalogFetching] = useState(true);
    const [readCatalogError, setReadCatalogError] = useState(false);
    const [readCatalog, setReadCatalog] = useState(false);

    //CreateExamAttempt
    const [createExamAttemptError, setCreateExamAttemptError] = useState(null);

    //Checkboxes
    const [questionOne, setQuestionOne] = useState(false);
    const [questionTwo, setQuestionTwo] = useState(false);
    const [questionThree, setQuestionThree] = useState(false);
    const [questionFour, setQuestionFour] = useState(false);

    //Time
    const [time, setTime] = useState(0);
    const [timeFetching, setTimeFetching] = useState(true); // State of checking whether theres a saved timer

    const [knowledgeAreas, setKnowledgeAreas] = useState(null);
    const [correct, setCorrect] = useState([]);
    const [incorrect, setIncorrect] = useState([]);
    const [skipped, setSkipped] = useState([]);
    const [selected, setSelected] = useState([]);

    const {name} = useParams(); 

    // If we're continuing with an exam, get the stored exam time
    useEffect(() => {
        const storedTime = localStorage.getItem(`${readCatalog.name}-timer`)
        console.log(`stored time - ${storedTime}`)
        if (storedTime)
        {
            console.log(`setting time - ${storedTime}`);
            setTime(storedTime);
        }
        else //If not set the time to the exam time
        {
            setTime(readCatalog.time * 60); //Time in minutes * 60 
        }
        setTimeFetching(false);
    }, [readCatalog])

    useEffect(() => {
        console.log(`${time}`);
    }, [time])

    useEffect(() => {
        if(finished)
        {
            //Save result to users exam attempts
            const timeTaken = (readCatalog.time * 60) - time;
            CreateExamAttempt(name, correct.map(el => el._id), incorrect.map(el => el._id), skipped.map(el => el._id), timeTaken, Cookies.get("jwtToken")).then(async (res) => {
                Notification("Success", "Exam Attempt Saved!")
            })
            .catch(err => {
                setCreateExamAttemptError(err);
            })
        }
    }, [name, finished, correct, incorrect, skipped, readCatalog, time])

    const [questionNo, setQuestionNo] = useState(1);

    const GetRandomExamQuestions = async (data) => {
        const shuffled = data.sort(() => 0.5 - Math.random());
        return shuffled.slice(0, 30);
    }

    if(readAllCatalogQuestionsFetching){
        IsLoggedIn(`practice/${name}`);
        const tokenFromCookie = Cookies.get('jwtToken');
        // If there are cookies from a previous exam and they haven't expired
        ReadAllCatalogQuestions(name, tokenFromCookie)
        .then(async (res) => {
            const questions = await GetRandomExamQuestions(res.data.detail);
            setReadAllCatalogsQuestions(questions);
        })
        .catch(err => {
            setReadAllCatalogQuestionsError(err)
        })
        .finally(() => {
            setReadAllCatalogQuestionsFetching(false);
        })
    }

    if(readCatalogFetching){
        ReadCatalog(name)
        .then(res => {
            setReadCatalog(res.data.detail[0]);
        })
        .catch(err => {
            setReadCatalogError(err)
        })
        .finally(() => {
            setReadCatalogFetching(false)
        }); 
    }
        
    if(readAllCatalogQuestionsError || readCatalogError || createExamAttemptError){
        return <Error title="Error" message="Please check your network connection"/>
    }
    else if(readAllCatalogQuestionsFetching || readCatalogFetching || timeFetching){
        return (
            <Loading/>
        )
    }
    else{
        if(started){
            // Page with the questions to answer
            if(!finished){
                return(
                    <QuestionPage time={time} readAllCatalogQuestions={readAllCatalogQuestions} setQuestionNo={setQuestionNo} questionNo={questionNo} setQuestionOne={setQuestionOne} 
                        questionOne={questionOne} setQuestionTwo={setQuestionTwo} questionTwo={questionTwo} setQuestionThree={setQuestionThree} questionThree={questionThree} 
                        setQuestionFour={setQuestionFour} questionFour={questionFour} readCatalog={readCatalog} setFinished={setFinished}
                        setSkipped={setSkipped} setCorrect={setCorrect} setIncorrect={setIncorrect} setTime={setTime} setSelected={setSelected} setKnowledgeAreas={setKnowledgeAreas}/>
                )
            }
            else{
                // If we're on the review page (list of marked answers)
                if(reviewQuestions)
                {
                    return (
                        <ReviewPage name={name} setReviewQuestions={setReviewQuestions} readAllCatalogQuestions={readAllCatalogQuestions} correct={correct} 
                            incorrect={incorrect} skipped={skipped} selected={selected}/>
                    )
                }
                // If we're on the result page (graphs and navigation buttons)
                else{
                    return (
                        <ResultPage name={name} correct={correct} incorrect={incorrect} skipped={skipped} readCatalog={readCatalog} setReviewQuestions={setReviewQuestions} knowledgeAreas={knowledgeAreas}/>
                    )
                }
            }

        }
        // Landing page of the exam
        else{
            return(
                <LandingPage name={name} readAllCatalogQuestions={readAllCatalogQuestions} readCatalog={readCatalog} setStarted={setStarted}/>
            )
        }
    }
}