import React from "react";

function StarHollow() {
    return (
        <svg
          style={{margin: "2px"}}
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="15"
          fill="#FFD700"
          version="1.1"
          viewBox="0 0 490.655 490.655"
          xmlSpace="preserve"
        >
          <path d="M490.102 188.327c-1.323-3.84-4.693-6.613-8.704-7.147L326.902 160.7 254.88 16.317c-3.584-7.232-15.509-7.232-19.093 0l-72.021 144.384L9.27 181.181a10.69 10.69 0 00-8.704 7.147 10.656 10.656 0 002.56 10.965l113.301 113.301-30.869 154.389a10.705 10.705 0 003.989 10.581c3.243 2.475 7.637 2.88 11.243 1.067l144.555-72.277L389.9 478.631a10.748 10.748 0 004.757 1.131 10.72 10.72 0 006.485-2.197 10.73 10.73 0 003.989-10.581l-30.869-154.389 113.301-113.301c2.837-2.86 3.819-7.105 2.539-10.967zm-93.867 41.92l-68.309 68.331 17.685 88.427a10.558 10.558 0 011.195 4.907c0 5.888-4.779 10.667-10.667 10.667h-.427c-1.621 0-3.243-.384-4.779-1.131l-85.632-42.795-85.632 42.795c-3.648 1.813-8.021 1.429-11.243-1.067a10.73 10.73 0 01-3.989-10.581l18.24-91.243-68.309-68.331a10.656 10.656 0 01-2.56-10.965c1.323-3.84 4.693-6.613 8.704-7.147l91.264-12.096 43.968-88.107c3.584-7.232 15.509-7.232 19.093 0l43.968 88.107 91.264 12.096a10.69 10.69 0 018.704 7.147 10.628 10.628 0 01-2.538 10.986z"></path>
        </svg>
      );
}

export default StarHollow;
