import React from "react";

export function ContactImg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      style={{width: "60%", marginTop: "8%"}}
    >
      <circle cx="256" cy="256" r="256" fill="#366796"></circle>
      <path
        fill="#263A7A"
        d="M512 256c0-10.202-.615-20.259-1.776-30.151L433.1 146.208l-97.661.109-30.929 65.905-41.901-43.279-95.056-1.724-10.569 62.105 150.788 150.983-4.646 4.513-115.184-115.184-92.615 89.995 152.214 152.214c2.81.091 5.627.153 8.459.153C397.384 512 512 397.384 512 256z"
      ></path>
      <path
        fill="#71E2EF"
        d="M294.397 90.6H140.028c-7.358 0-13.321 5.965-13.321 13.321v76.793c0 7.358 5.965 13.321 13.321 13.321h12.538v29.201c0 5.706 6.899 8.564 10.933 4.529l33.732-33.732h97.166c7.358 0 13.321-5.965 13.321-13.321V103.92c-.001-7.357-5.966-13.32-13.321-13.32z"
      ></path>
      <path
        fill="#38C6D9"
        d="M294.397 90.6H217.5v103.434h76.897c7.358 0 13.321-5.965 13.321-13.321V103.92c-.001-7.357-5.966-13.32-13.321-13.32z"
      ></path>
      <g fill="#366695">
        <path d="M152.566 121.518H281.85900000000004V130.922H152.566z"></path>
        <path d="M152.566 147.377H281.85900000000004V156.781H152.566z"></path>
      </g>
      <path
        fill="#FEE187"
        d="M269.321 142.317H423.69c7.358 0 13.321 5.965 13.321 13.321v76.793c0 7.358-5.965 13.321-13.321 13.321h-12.538v29.201c0 5.706-6.899 8.564-10.933 4.529l-33.732-33.732h-97.166c-7.358 0-13.321-5.965-13.321-13.321v-76.791c0-7.358 5.965-13.321 13.321-13.321z"
      ></path>
      <path
        fill="#FFC61B"
        d="M423.689 142.317h-88.964v103.434h31.761l33.732 33.732c4.036 4.034 10.933 1.177 10.933-4.529v-29.201h12.538c7.358 0 13.321-5.965 13.321-13.321v-76.795c0-7.357-5.965-13.32-13.321-13.32z"
      ></path>
      <circle cx="139.636" cy="312.579" r="64.646" fill="#FFEDB5"></circle>
      <g fill="#386895">
        <circle cx="113.192" cy="304.097" r="5.877"></circle>
        <circle cx="167.546" cy="304.097" r="5.877"></circle>
        <path d="M122.158 339.111c4.13 5.077 10.423 8.326 17.479 8.326 7.056 0 13.348-3.248 17.479-8.326h-34.958z"></path>
      </g>
      <circle cx="346.505" cy="346.022" r="64.646" fill="#FFEDB5"></circle>
      <g fill="#386895">
        <circle cx="320.06" cy="329.852" r="5.877"></circle>
        <circle cx="374.415" cy="329.852" r="5.877"></circle>
        <path d="M369.033 365.116c0 12.441-10.087 22.528-22.528 22.528-12.441 0-22.528-10.087-22.528-22.528h45.056z"></path>
      </g>
      <g fill="#366695">
        <path d="M281.859 173.235H411.152V182.639H281.859z"></path>
        <path d="M281.859 199.094H411.152V208.498H281.859z"></path>
      </g>
    </svg>
  );
}

