import { Post } from "./post";
import { getURL } from "./getURL";

export const GoogleLogin = (
  idToken: string,
  accessToken: string,
): Promise<any> => {
  return Post(getURL() + "googleLogin", 
    {
        idToken: idToken,
        accessToken: accessToken
    });
};
