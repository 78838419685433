import { Post } from "../post";
import { getURL } from "../getURL";

export const CreateExamAttempt = (
  exam: String, 
  correctAnswers: string[],
  incorrectAnswers: string[],
  skippedQuestions: string[],
  timeCompleted: string,
  jwt: string
): Promise<any> => {
  return Post(getURL() + "createExamAttempt", {
    exam: exam,
    correctAnswers: correctAnswers,
    incorrectAnswers: incorrectAnswers,
    skippedQuestions: skippedQuestions,
    timeCompleted: timeCompleted
  }, jwt);
};
